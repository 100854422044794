import React from "react";
import { HelmetProvider } from "react-helmet-async";
import "./src/components/Typekit.css";
import "./src/components/frog-id-week-2021-hero.css";
import "./src/components/BodyText.css";
import { DataProvider } from "./src/hooks/useData";

export const wrapRootElement = ({ element }) => (
  <DataProvider>
    <HelmetProvider>{element}</HelmetProvider>
  </DataProvider>
);
