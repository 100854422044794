import { theme } from "@chakra-ui/core";

export default {
  ...theme,
  fonts: {
    ...theme.fonts,
    body: "proxima-nova, sans-serif",
    heading: "proxima-nova, sans-serif",
    mono: "proxima-nova, sans-serif",
  },
  colors: {
    ...theme.colors,
    primary: {
      black: "#000000",
      blue: "#0f1e44",
      orange: "#E57500",
      yellow: "#FFD13A",
      green: "#71BE47",
    },
    bg: {
      gray: "#efefef",
      grayAlt: "#f5f5f5",
    },
    text: {
      gray: "#333",
    },
  },
  shadows: {
    ...theme.shadows,
    orange: "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(229 117 0 / 60%)",
  },
};
