import React, { useContext, useEffect, useReducer } from "react";

type State = any;
type Action =
  | { type: "request"; url: string }
  | { type: "success"; url: string; data: any }
  | { type: "failure"; url: string; error: string };

const initialState: State = {};

const DataContext = React.createContext(initialState);

function reducer(state: State, action: Action) {
  switch (action.type) {
    case "request":
      return {
        ...state,
        [action.url]: {
          loading: true,
          data: state[action.url]?.data,
          error: {}
        }
      };
    case "success":
      return {
        ...state,
        [action.url]: {
          loading: false,
          data: action.data,
          error: {}
        }
      };
    default:
      throw new Error();
  }
}

export const DataProvider: React.FC = props => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DataContext.Provider
      value={{
        data: state,
        dispatch
      }}
      {...props}
    />
  );
};

export const useData = (url: string) => {
  const { data, dispatch } = useContext(DataContext);
  const urlData = data?.[url];
  const performRequest = urlData?.data == null;

  useEffect(() => {
    if (performRequest) {
      dispatch({
        type: "request",
        url
      });
      fetch(url)
        .then(response => response.json())
        .then(data => {
          dispatch({
            type: "success",
            url,
            data
          });
        });
    }
  }, [url, performRequest]);

  if (urlData?.data == null) return { data: null };

  return {
    data: {
      [url]: urlData.data
    },
    loading: urlData.loading,
    error: urlData.error
  };
};
